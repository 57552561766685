import React from "react";
import "./Section7.css";
import SectionTitle from "../../components/Atoms/SectionTitle";
import { useEffect, useState } from "react";
import { useSession } from "../../common-backend/SessionContext";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Section7() {
  return (
    <div className="section7-container" id="intrebari">
      <SectionTitle title="Întrebări frecvente" side={false} />
      <div className="section7-container-core">
        <div className="section7-container-core-left">
          <img src="/media/hero_slide_2.png" />
        </div>
        <div className="section7-container-core-right">
          <ControlledAccordions />
        </div>
      </div>
    </div>
  );
}

function ControlledAccordions() {
  const { session } = useSession();
  const [expanded, setExpanded] = React.useState(false);
  const [groups, setGroups] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchQandA = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getQandA", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          setGroups(data.qanda);
        }
      });
  };

  useEffect(() => {
    fetchQandA();
  }, []);

  return (
    <div>
      {groups.map((el, index) => {
        let panelName = `panel${index}`;
        return (
          <Accordion expanded={expanded === panelName} onChange={handleChange(panelName)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                backgroundColor: "transparent",
                marginTop: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>{el.question}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>{el.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
