import React, { useState, useEffect } from "react";
import "./Login.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import { useSession } from "../common-backend/SessionContext";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ED1B26",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ED1B26",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#ED1B26",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ED1B26",
    },
  },
});

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const { setSession } = useSession();

  const handleLogin = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: username + "@gmail.com", password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
          setModalMessage(data.message);
          setShowPopup(true);
        } else {
          setSession(data);
          // Check user role and navigate accordingly
          if (data.userInfo.account_type === "Admin") {
            navigate("/vezi-instructori");
          } else if (data.userInfo.account_type === "Cursant") {
            navigate("/info");
          } else {
            navigate("/programeaza");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Automatically hide the popup after 2 seconds
    const timeoutId = setTimeout(() => {
      setShowPopup(false);
      setModalMessage(""); // Clear the message after fadeout
    }, 3000);

    // Clear the timeout when the component unmounts or when a new timeout is set
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showPopup]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login-container">
      <TopBar />
      <div className="login-container-core">
        <div className="login-container-left">
          <div className="login-container-left-core">
            <div className="login-container-left-welcome">
              <p>
                <b>Bine ai venit!</b>
              </p>
            </div>
            <div className="login-container-left-title">
              <p>
                <b>Conectare!</b>
              </p>
            </div>
            <div className="login-container-left-msg">
              <p>
                <b>Conectează-te la contul tău.</b>
              </p>
            </div>
            <div className="login-container-left-inputs">
              <div className="login-container-left-inputs-row">
                <CssTextField
                  label="Nume utilizator"
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
              <div className="login-container-left-inputs-row">
                {/* <CssTextField
                  label="Parolă"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                /> */}
                <TextField
                  label="Parolă"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
            <div className="login-container-left-helper"></div>
            <div className="login-container-left-buttons">
              <Button
                onClick={handleLogin}
                style={{
                  backgroundColor: "var(--main)",
                  padding: "18px 36px",
                  fontSize: "18px",
                  marginRight: "20px",
                }}
                variant="contained"
              >
                Conectare
              </Button>
            </div>
          </div>
        </div>
        <div className="login-container-right"></div>
        {showPopup && (
          <div className="login-error-popup">
            <p>{modalMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Login;
