import React from "react";
import "./Section5.css";
import SectionTitle from "../Atoms/SectionTitle";

export default function Section5() {
  const [team, setTeam] = React.useState([]);
  const fetchTeamMembers = async () => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getTeamMembers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        }
        console.log(data.team);
        setTeam(data.team);
      });
  };

  React.useEffect(() => {
    fetchTeamMembers();
  }, []);

  const getRandomName = () => {
    const names = ["team_3"]; // replace with your actual names
    return names[Math.floor(Math.random() * names.length)];
  };

  return (
    <div className="section5-container">
      <SectionTitle title="Echipa noastră" side={false} />
      <div className="section5-container-core">
        {team.map((member, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            key={index}
          >
            <img
              src={
                member.profile_picture === "male"
                  ? `/media/admins/${getRandomName()}.png`
                  : "/media/admins/team_1.png"
              }
            />
            <p className="centered-text">{member.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
