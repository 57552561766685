import React from "react";
import "./Blog.css";
import TopBar from "../components/TopBar";

export default function Blog() {
  return (
    <div className="blog-container">
      <TopBar />
      <div className="blog-container-core">
        <iframe
          src="https://www.blog.autojuv.ro/"
          title="Blog"
          frameborder="0"
          width="100%"
          height="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
}
