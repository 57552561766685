import React, { useEffect, useState } from "react";
import "./Rezultate.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSession } from "../../common-backend/SessionContext";
import ResultsPageChestionare from "../../components/ResultsPageChestionare";

export default function Rezultate() {
  const { session } = useSession();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersInformation, setUsersInformation] = useState([]);
  const [chestionareRezolvate, setChestionareRezolvate] = useState([]);
  const [selectedChestionar, setSelectedChestionar] = useState(null);

  const fetchUsers = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getUsers?student=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          const users = data.users.map((user) => ({
            name: user.user_name,
            user_name: user.email.split("@")[0],
          }));
          setUsers(users);
          setUsersInformation(data.users);
        }
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchChestionareRezolvate = async (name) => {
    const user = usersInformation.find((user) => user.user_name === name);
    fetch(`https://www.autojuv.magnusbooking.ro/api/getChestionarResults?userId=${user.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          if (data !== null) {
            setChestionareRezolvate(data.chestionare);
          }
        }
      });
  };

  function checkPassingScore(data) {
    const totalAnswers = data.answers.length;
    const correctAnswers = data.answers.filter((answer) => answer.isCorrect).length;

    const score = (correctAnswers / totalAnswers) * 100;

    return score >= 91.7;
  }

  function getPassingScore(data) {
    const totalAnswers = data.answers.length;
    const correctAnswers = data.answers.filter((answer) => answer.isCorrect).length;

    return `${correctAnswers}/${totalAnswers}`;
  }

  function getUserPicture(data) {
    const user = usersInformation.find((user) => user.id === data.userId);
    return user.profile_picture;
  }

  useEffect(() => {
    if (selectedUser) {
      fetchChestionareRezolvate(selectedUser.name);
    }
  }, [selectedUser]);

  const handleBackButtonClick = () => {
    setSelectedChestionar(null);
  };

  const questionsObject =
    selectedChestionar && selectedChestionar.answers
      ? { questions: selectedChestionar.answers.map((answer) => answer.question) }
      : null;

  return (
    <div className="rezultate-container">
      {selectedChestionar ? (
        <>
          <ResultsPageChestionare
            score={selectedChestionar.answers.reduce(
              (total, answer) => total + (answer.isCorrect ? 1 : 0),
              0
            )}
            quizData={questionsObject}
            answers={selectedChestionar.answers}
            handleBackButtonClick={handleBackButtonClick}
          />
        </>
      ) : (
        <>
          <div className="rezultate-container-top">
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                options={users}
                getOptionLabel={(option) => `${option.name} (${option.user_name})`}
                style={{ width: "auto" }}
                value={selectedUser} // control the value directly
                renderInput={(params) => (
                  <TextField {...params} label="Selecteza cursant" variant="outlined" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.name}&nbsp;
                    <span style={{ fontSize: "0.8em" }}>{`(${option.user_name})`}</span>
                  </li>
                )}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                  if (newValue) {
                    fetchChestionareRezolvate(newValue.name);
                  } else {
                    setChestionareRezolvate([]);
                  }
                }}
              />
            </FormControl>
          </div>
          <div className="rezultate-container-items">
            {chestionareRezolvate &&
              chestionareRezolvate.map((el, index) => {
                return (
                  <div
                    className="rezultate-container-items-el"
                    onClick={() => setSelectedChestionar(el)}
                    key={index}
                  >
                    <div className="rezultate-container-items-core">
                      <p
                        style={{
                          padding: "5px",
                          backgroundColor: "#29CC39",
                          textAlign: "center",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "200px",
                        }}
                      >
                        {el.dateAndTime}
                        <br></br>
                        {el.hour}
                      </p>
                    </div>
                    <div className="rezultate-container-items-core">
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {el.chestionarName}
                      </p>
                    </div>
                    <div className="rezultate-container-items-core">
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {el.userName}
                      </p>
                      <img src={getUserPicture(el)} />
                    </div>
                    <div className="rezultate-container-items-core">
                      <p
                        style={{
                          padding: "5px",
                          backgroundColor: `${checkPassingScore(el) == true ? "#29CC39" : "red"}`,
                          textAlign: "center",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "200px",
                        }}
                      >
                        {getPassingScore(el)}
                      </p>
                    </div>
                    <div
                      className="rezultate-container-items-core"
                      style={{ flexDirection: "column" }}
                    >
                      <img
                        src={
                          checkPassingScore(el) == true ? "/media/passed.png" : "/media/failed.png"
                        }
                        style={{ marginBottom: "5px" }}
                      />
                      <p style={{ fontWeight: "bold" }}>
                        {checkPassingScore(el) == true ? "Admis" : "Respins"}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}
