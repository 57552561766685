import React from "react";
import "./Section8.css";
import SectionTitle from "../Atoms/SectionTitle";

export default function Section8() {
  const items = [
    "Act de identitare valabil atât la înscriere cât și pe toată perioada școlarizării;",
    "Fișă medicală (valabilă 1 an);",
    "Aviz psihologic (valabil 6 luni);",
    "Cazier judiciar (valabil 6 luni);",
    <>
      Taxă permis 89 lei (valabilă 3 ani) - se achită la CEC Bank / mandat poștal / sistemul
      național electronic de plată online (S.N.E.P.) -{" "}
      <a href="https://www.ghiseul.ro/ghiseul/public/">ghiseul.ro</a>
    </>,
  ];
  return (
    <div className="section8-container" id="acte-necesare">
      <SectionTitle title="Informații utile" side={true} />
      <div className="section8-container-core">
        <h4> Acte necesare în vederea înscrierii la școala de șoferi:</h4>
        <div className="section8-container-core-items">
          {items.map((el) => {
            return (
              <div className="section8-container-core-items-el">
                <img src="/media/icons/checked.png" />
                <span>{el}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
