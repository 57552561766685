import { BrowserRouter,Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { SessionProvider } from "./common-backend/SessionContext";

// Public pages
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
// Administrator
import Grupe from './pages/Administrator/Grupe';
import Intrebari from './pages/Administrator/Intrebari';
import ParcAuto from './pages/Administrator/ParcAuto';
import Studenti from './pages/Administrator/Studenti';
import VeziProfesori from './pages/Administrator/VeziProfesori';

// Profesor
import Programeaza from "./pages/Profesor/Programeaza";

// Elev
import Informatii from "./pages/Elev/Informatii";

import ProtectedRoute from "./common-backend/ProtectedRoute";

import DashTemplate from "./components/DashTemplate";
import Conturi from "./pages/Administrator/Conturi";
import Echipa from "./pages/Administrator/Echipa";
import Galerie from "./pages/Galerie";
import Chestionar from "./pages/Administrator/Chestionar";
import Rezultate from "./pages/Administrator/Rezultate";
import RezultatChestionar from "./pages/Administrator/RezultatChestionar";
function App() {
  Modal.setAppElement('#root');
  return (
    <SessionProvider>
      <BrowserRouter>
        <Routes>
          {/* Public */}
          <Route index element={<Landing />} />
          <Route path="login" element={<Login />} />
          <Route path="contact" element={<Contact />} />
          <Route path="galerie" element={<Galerie />} />
          <Route path="blog" element={<Blog />} />

          {/* Administrator */}
          <Route path="/vezi-instructori" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={0}
                CoreEl={VeziProfesori}
              />
            </ProtectedRoute>
          } />
          <Route path="/serii" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={1}
                CoreEl={Grupe}
              />
            </ProtectedRoute>
          } />
          <Route path="/intrebari" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={2}
                CoreEl={Intrebari}
              />
            </ProtectedRoute>
          } />
          <Route path="/cursanti" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={3}
                CoreEl={Studenti}
              />
            </ProtectedRoute>
          } />
          <Route path="/parc-auto" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={4}
                CoreEl={ParcAuto}
              />
            </ProtectedRoute>
          } />
          <Route path="/conturi" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={5}
                CoreEl={Conturi}
              />
            </ProtectedRoute>
          } />
          <Route path="/echipa" element={
            <ProtectedRoute roles={['Admin']}>
              <DashTemplate
                menuIndex={6}
                CoreEl={Echipa}
              />
            </ProtectedRoute>
          } />
          <Route path="/programeaza" element={
            <ProtectedRoute roles={['Instructor']}>
              <DashTemplate
                menuIndex={7}
                CoreEl={Programeaza}
              />
            </ProtectedRoute>
          } />
          <Route path="/info" element={
            <ProtectedRoute roles={['Cursant']}>
              <DashTemplate
                menuIndex={8}
                CoreEl={Informatii}
              />
            </ProtectedRoute>
          } />
          <Route path="/chestionar" element={
            <ProtectedRoute roles={['Cursant']}>
            <DashTemplate
              menuIndex={9}
              CoreEl={Chestionar}
            />
            </ProtectedRoute>
          } />
          <Route path="/rezultate" element={
            <ProtectedRoute roles={['Admin']}>
            <DashTemplate
              menuIndex={10}
              CoreEl={Rezultate}
            />
            </ProtectedRoute>
          } />
          <Route path="/rezultat-chestionar" element={
            <ProtectedRoute roles={['Admin']}>
            <DashTemplate
              menuIndex={11}
              CoreEl={RezultatChestionar}
            />
            </ProtectedRoute>
          } />

        </Routes>
      </BrowserRouter>
    </SessionProvider>
  );
}

export default App;
