import React, { useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import "./Conturi.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CssTextField } from "../Login";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSession } from "../../common-backend/SessionContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  border: "none",
  outline: "none",
  borderRadius: "10px",
};

export default function Echipa() {
  const { session } = useSession();

  const [team, setTeam] = React.useState([]);
  const [memberName, setMemberName] = React.useState(""); // new state variable for member name
  const [profilePicture, setProfilePicture] = React.useState(""); // new state variable for profile picture

  const [errorPostingData, setErrorPostingData] = React.useState("");
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);

  const removeTeamMember = async (memberName, profilePicture) => {
    console.log(memberName, profilePicture);
    fetch(
      `https://www.autojuv.magnusbooking.ro/api/removeTeamMember?teamMember=${memberName}&profilePicture=${profilePicture}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setErrorPostingData(data.error.message);
          handleErrorOpen();
        }
        setErrorPostingData(data.message);
        handleErrorOpen();
        fetchTeamMembers();
      });
  };

  const addTeamMember = async () => {
    console.log(memberName, profilePicture);
    fetch(
      `https://www.autojuv.magnusbooking.ro/api/addTeamMember?teamMember=${memberName}&profilePicture=${profilePicture}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setErrorPostingData(data.error.message);
          handleErrorOpen();
        }
        setErrorPostingData(data.message);
        handleErrorOpen();
        fetchTeamMembers();
      });
  };

  const fetchTeamMembers = async () => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getTeamMembers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        }
        setTeam(data.team);
      });
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const handleName = (event) => {
    setMemberName(event.target.value);
  };
  const handleProfilePicture = (event) => {
    setProfilePicture(event.target.value);
  };

  return (
    <div className="conturi-container">
      <div className="conturi-container-left">
        {team.map((el, index) => {
          return (
            <div className="conturi-container-left-row" key={index}>
              <div
                style={{
                  backgroundColor: `${
                    index % 2 == 0 ? "rgb(41, 204, 57, 0.2)" : "rgb(51, 191, 255, 0.2)"
                  }`,
                  border: `2px solid ${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                }}
                className="conturi-container-left-row-core"
              >
                <div className="conturi-container-left-row-core-img">
                  {el.profile_picture ? (
                    <img src={"/media/icons/userIcon.png"} />
                  ) : (
                    <AiOutlineUser size={10} />
                  )}
                </div>
                <div className="conturi-container-left-row-core-data">
                  <p>{el.name}</p>
                </div>
              </div>
              <img
                src="/media/icons/delete.png"
                onClick={() => removeTeamMember(el.name, el.profile_picture)}
              />
            </div>
          );
        })}
      </div>
      <div className="conturi-container-right">
        <div className="conturi-container-right-row">
          <p>Adauga un membru nou pe site:</p>
        </div>
        <div className="conturi-container-right-row">
          <CssTextField
            label="Nume membru"
            value={memberName}
            onChange={(event) => {
              handleName(event);
            }}
            sx={{
              width: "100%",
            }}
          />
        </div>
        <div className="conturi-container-right-row">
          <input
            type="radio"
            value="male"
            checked={profilePicture === "male"}
            onChange={(event) => {
              handleProfilePicture(event);
            }}
          />
          <label>
            <b>Bărbat</b>
          </label>

          <input
            type="radio"
            value="female"
            checked={profilePicture === "female"}
            onChange={(event) => {
              handleProfilePicture(event);
            }}
          />
          <label>
            <b>Femeie</b>
          </label>
        </div>
        <div className="conturi-container-right-row">
          <Button
            style={{
              backgroundColor: "var(--main)",
              padding: "18px 36px",
              fontSize: "18px",
              marginRight: "20px",
              marginTop: "20px",
            }}
            variant="contained"
            disabled={!memberName}
            onClick={addTeamMember}
          >
            Adaugă
          </Button>
        </div>
        <Modal
          open={errorOpen}
          onClose={handleErrorClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalMesaj handleClose={handleErrorClose} errorPostingData={errorPostingData} />
          </Box>
        </Modal>
      </div>
    </div>
  );
}
const ModalMesaj = ({ handleClose, errorPostingData }) => {
  return (
    <div className="vezi-profesori-left-modal">
      <h3
        style={{
          fontSize: "18px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {errorPostingData}
      </h3>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
          }}
          variant="contained"
          onClick={handleClose}
        >
          Închide
        </Button>
      </div>
    </div>
  );
};
