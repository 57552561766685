import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import "./Testimonials.css";

export default function Testimonials(props) {
  var items = [
    {
      img: "/media/Reviews/1.png",
      name: "Anca Mihailiuc",
      description:
        "Recomand cu încredere! Admisă din prima! Domnul Marian Juverdeanu, fără de care nu aș fi reușit și care m-a susținut de la început, este un om excepțional, răbdător și înțelegător. Atmosfera la orele de condus a fost foarte plăcută. Întreaga echipă se asigura să ai cele mai bune condiții și să nu-ți lipsească nimic! Vă mulțumesc pentru tot!",
    },
    {
      img: "/media/Reviews/2.png",
      description: `Recomand cu încredere Școala de Șoferi Auto Juv! Este tot ce ți-ai dori de la o școală de șoferi. Excelează din toate punctele de vedere. Este formată dint-o echipă de oameni foooarte faini, amabili și tot timpul cu zâmbetul pe buze. ...`,
      name: "Ștefan Ghebuță",
    },
    {
      img: "/media/Reviews/3.png",
      description:
        "Ce frumos e când găsești oameni pasionați să facă lucruri bine! Experiența mea cu școala Auto Juv a fost impecabilă! Am comunicat ușor cu ei și au fost mereu prompți și serioși. Tot personalul este amabil și atent să fii la zi cu actele. ...",
      name: `Ștefania Ureche`,
    },
    {
      img: "/media/Reviews/4.png",
      description: `Datorita domnului instructor Marian Juverdeanu am reusit sa depasesc frica de a conduce! Este calm, rabdator si foarte clar in explicatii! Toata echipa este minunata, Eduard si Sabina sunt extrem de amabili si profesionisti! Rezultatul a fost: admisa din prima! Recomand aceasta scoala cu toata caldura!`,
      name: "Honceru Andreea",
    },
    {
      img: "/media/Reviews/5.png",
      description: `Recomand ,din toata inima, Școala de Șoferi Auto Juv pentru profesionalism și dedicare fata de elevi. Cea mai buna școală auto cu cei mai buni instructori. Rezultatele elevilor demonstrează spusele mele. Recomand Școala de Șoferi Auto Juv.`,
      name: "Katy holicov",
    },
    {
      img: "/media/Reviews/6.png",
      description: `Admis din prima datorită domnului instructor Eduard Juverdeanu!Recomand școala de șoferi Auto Juv !`,
      name: "Narcis Rusu",
    },
    {
      img: "/media/Reviews/7.png",
      description: `Instructori f calmi si intelegatori, oameni de nota 11 :)`,
      name: "Robert Adrian",
    },
    {
      img: "/media/Reviews/8.png",
      description: `Cea mai placuta experiența ! (5stele)
      Personal cu experienta (5stele).Instructor calm si rabdator . (Traseu luat din prima )
      Atmosfera frumoasa si mult ajutor si rabdare ,din partea secretarilor si profesorului de legislatie ( mi au oferit mult ajutor ,inainte sa dau sala ) ,sala luată 24/2.
      Recomand cu incredere !
      Multumesc foarte mult pt ajutorul si rabdarea pe care a ti avut o cu mine !!`,
      name: `Ionut`,
    },
    {
      img: "/media/Reviews/9.png",
      description: `Cea mai buna scoala !
      Niște oameni deosebiți, și care dau dovada de profesionalism adevărat !`,
      name: "Denis Craciun",
    },
  ];

  return (
    <div className="testimonials-container">
      <Carousel>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
}

function Item(props) {
  return (
    <div className="testi-core-item">
      <div className="testi-core-left">
        <img src={props.item.img} />
        <span>Cursant</span>
        <p>{props.item.name}</p>
      </div>
      <div className="testi-core-right">
        <div className="testi-core-right-top">
          <p>{props.item.description}</p>
        </div>
        <div className="testi-core-right-bot">
          <img src="/media/icons/star.png" />
          <img src="/media/icons/star.png" />
          <img src="/media/icons/star.png" />
          <img src="/media/icons/star.png" />
          <img src="/media/icons/star.png" />
        </div>
      </div>
    </div>
  );
}
