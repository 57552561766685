import React from "react";
import { useEffect, useState } from "react";
import "./ParcAuto.css";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CssTextField } from "../Login";
import { useSession } from "../../common-backend/SessionContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  border: "none",
  outline: "none",
  borderRadius: "10px",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
        width: 320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ParcAuto() {
  const { session } = useSession();
  const [photosFirst, setPhotosFirst] = useState([]);
  const [photosSecond, setPhotosSecond] = useState([]);
  const [description, setDescription] = useState("");
  const [errorPostingData, setErrorPostingData] = React.useState("");

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // First modal logic
  const [openFirst, setOpenFirst] = React.useState(false);
  const handleOpenFirst = () => setOpenFirst(true);
  const handleCloseFirst = () => setOpenFirst(false);

  // Second modal logic
  const [openSecond, setOpenSecond] = React.useState(false);
  const handleOpenSecond = () => setOpenSecond(true);
  const handleCloseSecond = () => setOpenSecond(false);

  // Message modal logic
  const [messageOpen, setMessageOpen] = React.useState(false);
  const handleMessageOpen = () => setMessageOpen(true);
  const handleMessageClose = () => setMessageOpen(false);

  const fetchEntities = async () => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getEntities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          setDescription(data.entities[0].description);
          setPhotosFirst(data.entities[0].photos.links);
          setPhotosSecond(data.entities[0].photos_second.links);
        }
      });
  };

  const editEntities = async ({ data, action, section }) => {
    console.log(data, action, section);
    fetch(`https://www.autojuv.magnusbooking.ro/api/editEntitie`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify({ ...data, action, section }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
          setErrorPostingData(data.error.message);
          handleMessageOpen();
        } else {
          console.log(data);
          setErrorPostingData(data.message);
          handleMessageOpen();
          fetchEntities();
        }
      });
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="parcauto-container">
      <div className="parcauto-container-header">
        <h4>Editează descriere website:</h4>
        <Textarea
          aria-label="minimum height"
          minRows={9}
          placeholder="Minimum 3 rows"
          value={description}
          onChange={(e) => handleDescriptionChange(e)}
          sx={{
            resize: "none",
            marginTop: "20px",
            color: "#2B3674",
            fontWeight: "bold",
            width: "100%",
            height: "200px",
          }}
        />
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "15px 30px",
            fontSize: "1rem",
            marginRight: "20px",
            marginTop: "30px",
          }}
          variant="contained"
          onClick={() => editEntities({ data: { description: description } })}
        >
          Salveaza
        </Button>
      </div>

      <div className="parcauto-container-content">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Galerie" {...a11yProps(0)} />
            <Tab label="Cursanti" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="parcauto-container-content-adauga">
            <div className="vezi-profesori-left-top">
              <Modal
                open={messageOpen}
                onClose={handleMessageClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ModalMessage
                    handleMessageClose={handleMessageClose}
                    message={errorPostingData}
                  />
                </Box>
              </Modal>
              <Modal
                open={openFirst}
                onClose={handleCloseFirst}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ModalPoze
                    handleClose={handleCloseFirst}
                    editEntities={editEntities}
                    section={"first"}
                  />
                </Box>
              </Modal>

              <img
                title="Adauga profesor"
                src="/media/icons/button.png"
                onClick={handleOpenFirst}
              />
              <p>Adaugă o poză în secțiunea galerie</p>
            </div>
          </div>
          <div className="parcauto-container-content-deep">
            {photosFirst.map((el, index) => {
              return (
                <div className="parcauto-container-content-el" key={index}>
                  <img src={el} />
                  <div className="parcauto-container-content-el-del">
                    <img
                      src="/media/icons/delete.png"
                      title="Sterge Imagine"
                      onClick={() =>
                        editEntities({ data: { photo: el }, action: "delete", section: "first" })
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="parcauto-container-content-adauga" style={{ marginTop: 100 }}>
            <div className="vezi-profesori-left-top">
              <Modal
                open={messageOpen}
                onClose={handleMessageClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ModalMessage
                    handleMessageClose={handleMessageClose}
                    message={errorPostingData}
                  />
                </Box>
              </Modal>
              <Modal
                open={openSecond}
                onClose={handleCloseSecond}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <ModalPoze
                    handleClose={handleCloseSecond}
                    editEntities={editEntities}
                    section={"second"}
                  />
                </Box>
              </Modal>

              <img
                title="Adauga profesor"
                src="/media/icons/button.png"
                onClick={handleOpenSecond}
              />
              <p>Adaugă o poză în secțiunea cursanți</p>
            </div>
          </div>
          <div className="parcauto-container-content-deep">
            {photosSecond.map((el, index) => {
              return (
                <div className="parcauto-container-content-el" key={index}>
                  <img src={el} />
                  <div className="parcauto-container-content-el-del">
                    <img
                      src="/media/icons/delete.png"
                      title="Sterge Imagine"
                      onClick={() =>
                        editEntities({ data: { photo: el }, action: "delete", section: "second" })
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </CustomTabPanel>
      </div>
    </div>
  );
}

const ModalPoze = ({ handleClose, editEntities, section }) => {
  const [link, setLink] = React.useState("");

  const handleLink = (event) => {
    setLink(event.target.value);
  };

  const handleAdd = () => {
    console.log(section);
    // Call editEntities with photo data
    editEntities({ data: { photo: link }, section });
    handleClose();
  };

  return (
    <div className="vezi-profesori-left-modal">
      <h3>Adauga poza</h3>
      <div className="vezi-profesori-left-modal-row">
        <CssTextField
          label="Link"
          sx={{
            width: "100%",
          }}
          onChange={(event) => {
            handleLink(event);
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
            marginRight: "20px",
          }}
          variant="contained"
          onClick={handleAdd}
        >
          Adauga
        </Button>
      </div>
    </div>
  );
};

const ModalMessage = ({ handleMessageClose, message }) => {
  return (
    <div className="vezi-profesori-left-modal">
      <h3
        style={{
          fontSize: "18px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {message}
      </h3>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
          }}
          variant="contained"
          onClick={handleMessageClose}
        >
          Închide
        </Button>
      </div>
    </div>
  );
};
