import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useEffect, useState } from "react";
import "./Programeaza.css";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CssTextField } from "../Login";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Select from "@mui/material/Select";
import { useSession } from "../../common-backend/SessionContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  border: "none",
  outline: "none",
  borderRadius: "10px",
};
export default function Programeaza() {
  const [value, setValue] = React.useState(dayjs());

  // Modal logic
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [listaAppointments, setListaAppointments] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [errorPostingData, setErrorPostingData] = React.useState("");

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);
  const { session } = useSession();
  const [users, setUsers] = useState([]);

  const [editingAppointment, setEditingAppointment] = useState(null);
  const [newAppointmentType, setNewAppointmentType] = useState("");

  const [originalAppointmentType, setOriginalAppointmentType] = useState("");

  const modifyDate = (date) => {
    const isoString = date.toISOString();
    const dateObject = new Date(isoString);

    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const fetchAppointments = async (modifiedDate) => {
    fetch(
      `https://www.autojuv.magnusbooking.ro/api/getAppointments?date=${modifiedDate}&instructor=${session.userInfo.user_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        }
        console.log(data);
        setListaAppointments(data.appointments);
      });
  };

  const fetchUsers = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getUsers?student=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          const users = data.users.map((user) => ({
            name: user.user_name,
            user_name: user.email.split("@")[0],
            id: user.id, // Assuming the user's ID is stored in the 'id' property
          }));
          setUsers(users);
        }
      });
  };

  const addAppointments = async (appointmentData) => {
    //cursantID, appointment_type, , [oraStart, oraStop], cursant, instructorName, instructorPicture, Date
    appointmentData.date = modifyDate(value);
    appointmentData.instructor = session.userInfo.user_name;
    appointmentData.instructor_picture = session.userInfo.profile_picture;
    fetch(`https://www.autojuv.magnusbooking.ro/api/addAppointments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify(appointmentData), // Include the appointment data in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
          setErrorPostingData(data.error.message);
          handleErrorOpen();
        } else {
          setErrorPostingData("Programarea a fost adaugata cu succes!");
          fetchAppointments(modifyDate(value));
          handleErrorOpen();
        }
      });
  };

  const editAppointments = async (appointmentData) => {
    //cursantID, appointment_type, , [oraStart, oraStop], cursant, instructorName, instructorPicture, Date
    let currentDateTime = new Date();
    let hours = currentDateTime.getHours();
    let minutes = currentDateTime.getMinutes();

    // Pad the minutes with leading zeros if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let currentTime = hours + ":" + minutes;

    appointmentData.date = modifyDate(value);
    appointmentData.instructor = session.userInfo.user_name;
    appointmentData.instructor_picture = session.userInfo.profile_picture;
    appointmentData.dateAndTimeChangedDataFromIntructor =
      appointmentData.date + " - " + currentTime;
    console.log(appointmentData);
    fetch(`https://www.autojuv.magnusbooking.ro/api/editAppointments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify(appointmentData), // Include the appointment data in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
          setErrorPostingData(data.error.message);
          handleErrorOpen();
        } else {
          setErrorPostingData("Programarea a fost editată cu succes!");
          fetchAppointments(modifyDate(value));
          handleErrorOpen();
        }
      });
  };

  useEffect(() => {
    fetchAppointments(modifyDate(value));
  }, [value]);
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="programeaza-container">
      <div className="programeaza-container-header">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Calendar"
            value={value}
            // onChange={(newValue) => setValue(newValue)}
            onChange={(newValue) => {
              setValue(newValue);
              fetchAppointments(modifyDate(newValue));
            }}
          />
        </LocalizationProvider>
        <div className="vezi-profesori-left-top">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ModalProgramare
                users={users}
                addAppointments={addAppointments}
                handleClose={handleClose}
              />
            </Box>
          </Modal>

          <img title="Adauga profesor" src="/media/icons/button.png" onClick={handleOpen} />
          <p>Adauga programare</p>
        </div>

        <Modal
          open={errorOpen}
          onClose={handleErrorClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalMesaj handleClose={handleErrorClose} errorPostingData={errorPostingData} />
          </Box>
        </Modal>
      </div>
      <div className="programeaza-container-content">
        {listaAppointments.map((el, index) => {
          if (editingAppointment === el) {
            return (
              <div
                style={{
                  backgroundColor: `${
                    index % 2 == 0 ? "rgb(41, 204, 57, 0.2)" : "rgb(51, 191, 255, 0.2)"
                  }`,
                  border: `2px solid ${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                  display: "flex",
                }}
                className="vezi-profesori-left-right-bot-row"
                key={index}
              >
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <textarea
                    value={newAppointmentType}
                    onChange={(e) => setNewAppointmentType(e.target.value)}
                  />
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <p
                    style={{
                      backgroundColor: `${
                        index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"
                      }`,
                    }}
                    className="vezi-profesori-left-right-bot-row-cell-hour"
                  >
                    {el.time}
                  </p>
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <p>Elev: {el.student || ""}</p>
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  {el.instructor_picture ? <img src={el.instructor_picture} /> : <AiOutlineUser />}
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <img
                    src="/media/icons/check.png"
                    onClick={() => {
                      const message = `Instructorul a modificat tipul de programare de la "${originalAppointmentType}" la "${newAppointmentType}".`;

                      if (originalAppointmentType !== newAppointmentType) {
                        el.appointment_type = newAppointmentType;
                        el.instructor_changes.old = originalAppointmentType;
                        el.instructor_changes.new = newAppointmentType;
                        editAppointments(el);
                      }
                      setEditingAppointment(null);
                      setNewAppointmentType("");
                    }}
                  />
                  <img
                    src="/media/icons/close.png"
                    onClick={() => {
                      setEditingAppointment(null);
                      setNewAppointmentType("");
                    }}
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  backgroundColor: `${
                    index % 2 == 0 ? "rgb(41, 204, 57, 0.2)" : "rgb(51, 191, 255, 0.2)"
                  }`,
                  border: `2px solid ${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                  display: "flex",
                }}
                className="vezi-profesori-left-right-bot-row"
                key={index}
              >
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <p>
                    {(el.appointment_type || "").split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <p
                    style={{
                      backgroundColor: `${
                        index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"
                      }`,
                    }}
                    className="vezi-profesori-left-right-bot-row-cell-hour"
                  >
                    {el.time}
                  </p>
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <p>Elev: {el.student || ""}</p>
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  {el.instructor_picture ? <img src={el.instructor_picture} /> : <AiOutlineUser />}
                </div>
                <div className="vezi-profesori-left-right-bot-row-cell">
                  <img
                    src="/media/icons/pen.png"
                    onClick={() => {
                      setEditingAppointment(el);
                      setOriginalAppointmentType(el.appointment_type);
                      setNewAppointmentType(el.appointment_type);
                    }}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

const ModalProgramare = ({ users, addAppointments, handleClose }) => {
  const [cursant, setCursant] = React.useState("");
  const [oraStart, setOraStart] = React.useState("");
  const [oraStop, setOraStop] = React.useState("");
  const [tipInstruire, setTipInstruire] = React.useState("");

  const handleCursantChange = (event) => {
    setCursant(event.target.value);
  };
  const handleOraStart = (event) => {
    setOraStart(event.target.value);
  };
  const handleOraStop = (event) => {
    setOraStop(event.target.value);
  };
  const handleTipInstruire = (event) => {
    setTipInstruire(event.target.value);
  };

  const handleAddAppointment = () => {
    //get the id of the cursant
    const cursantID = users.find((user) => user.name === cursant)?.id ?? "";

    const appointmentData = {
      cursant,
      cursantID,
      ora: oraStart && oraStop ? `${oraStart} - ${oraStop}` : oraStart || oraStop || "",
      tipInstruire,
      instructor_changes: {
        old: tipInstruire,
        new: "",
      },
    };

    addAppointments(appointmentData);

    // Reset the form
    setCursant("");
    setOraStart("");
    setOraStop("");
    setTipInstruire("");
    handleClose();
  };

  // Generate time options from 7:00 to 23:00 in 15 minute intervals
  const timeOptions = [];
  for (let i = 7; i < 24; i++) {
    timeOptions.push(<MenuItem key={`${i}:00`} value={`${i}:00`}>{`${i}:00`}</MenuItem>);
    timeOptions.push(<MenuItem key={`${i}:15`} value={`${i}:15`}>{`${i}:15`}</MenuItem>);
    timeOptions.push(<MenuItem key={`${i}:30`} value={`${i}:30`}>{`${i}:30`}</MenuItem>);
    timeOptions.push(<MenuItem key={`${i}:45`} value={`${i}:45`}>{`${i}:45`}</MenuItem>);
  }
  return (
    <div className="vezi-profesori-left-modal">
      <h3>Adauga programare</h3>
      <div className="vezi-profesori-left-modal-row">
        <Autocomplete
          id="combo-box-demo"
          options={users}
          getOptionLabel={(option) => `${option.name} (${option.user_name})`}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="Alege cursant" variant="outlined" fullWidth />
          )}
          value={users.find((user) => user.name === cursant)}
          onChange={(event, newValue) => {
            setCursant(newValue ? newValue.name : "");
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Ora start</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ora start"
            value={oraStart}
            onChange={(event) => {
              handleOraStart(event);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                },
              },
            }}
          >
            {timeOptions}
          </Select>
        </FormControl>
      </div>
      <div className="vezi-profesori-left-modal-row">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Ore stop</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ora stop"
            value={oraStop}
            onChange={(event) => {
              handleOraStop(event);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                },
              },
            }}
          >
            {timeOptions}
          </Select>
        </FormControl>
      </div>
      <div className="vezi-profesori-left-modal-row">
        <textarea
          style={{
            width: "100%",
          }}
          value={tipInstruire}
          onChange={(event) => {
            handleTipInstruire(event);
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
            marginRight: "20px",
          }}
          variant="contained"
          onClick={handleAddAppointment}
          disabled={!cursant || !oraStart || !oraStop || !tipInstruire}
        >
          Adauga
        </Button>
      </div>
    </div>
  );
};

const ModalMesaj = ({ handleClose, errorPostingData }) => {
  return (
    <div className="vezi-profesori-left-modal">
      <h3
        style={{
          fontSize: "18px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {errorPostingData}
      </h3>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
          }}
          variant="contained"
          onClick={handleClose}
        >
          Închide
        </Button>
      </div>
    </div>
  );
};
