import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [session, setSessionState] = useState(() => {
        // Attempt to get session from cookies on initialization
        //console.log('Getting session from cookies');
        const storedSession = Cookies.get('session');
        return storedSession ? JSON.parse(storedSession) : false;
    });

    // Inside SessionProvider
    const setSession = (newSession) => {
        setSessionState(newSession);
        // Save the session to cookies with an expiration time of 7 days
        console.log('Setting session to cookies');
        Cookies.set('session', JSON.stringify(newSession), { expires: 7 });
    };

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};
