import React from "react";
import "./Conturi.css";
import { AiOutlineUser } from "react-icons/ai";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { CssTextField } from "../Login";
import Select from "@mui/material/Select";
import { useEffect, useState, useContext } from "react";
import { useSession } from "../../common-backend/SessionContext";

export default function Conturi() {
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [profilePictureValid, setProfilePictureValid] = useState(true);
  const [accountTypeValid, setAccountTypeValid] = useState(true);

  const [listaConturi, setListaConturi] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [accountType, setAccountType] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const { session } = useSession();
  const userInfo = session.userInfo;

  const handleDeleteClick = async (userId) => {
    fetch("https://www.autojuv.magnusbooking.ro/api/deleteUser", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setModalMessage("User has been deleted");
          fetchUsers();
          setIsModalOpen(true);
        }
      });
  };

  const fetchUsers = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getUsers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setModalMessage("Error fetching users: " + data.error.message);
          setIsModalOpen(true);
        } else {
          setListaConturi(data.users);
        }
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddClick = async () => {
    // Reset all validation hooks
    setNameValid(true);
    setEmailValid(true);
    setPasswordValid(true);
    setProfilePictureValid(true);
    setAccountTypeValid(true);

    // Check if any value is empty and set validation hooks accordingly
    if (!name) {
      setNameValid(false);
    }
    if (!email) {
      setEmailValid(false);
    }
    if (!password) {
      setPasswordValid(false);
    }
    // if (!profilePicture) {
    //     setProfilePictureValid(false);
    // }
    if (!accountType) {
      setAccountTypeValid(false);
    }

    // Check if any validation failed and return
    if (!name || !email || !password || !accountType) {
      return;
    }

    fetch("https://www.autojuv.magnusbooking.ro/api/addUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify({
        email: email + "@gmail.com",
        password,
        name,
        profilePicture,
        accountType,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setModalMessage("Utilizatorul a fost adăugat");
          fetchUsers(); // Refresh the user list
        }
        if (data.error) {
          setModalMessage("Eroare: " + data.error);
        }
        setIsModalOpen(true);
      });
  };

  return (
    <div className="conturi-container">
      <div className="conturi-container-left">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "10px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
          }}
          variant="contained"
          onClick={() => {
            fetchUsers();
          }}
        >
          Refresh
        </Button>
        {listaConturi.map((el, index) => {
          return (
            <div key={el.id} className="conturi-container-left-row">
              <div
                style={{
                  backgroundColor: `${
                    index % 2 == 0 ? "rgb(41, 204, 57, 0.2)" : "rgb(51, 191, 255, 0.2)"
                  }`,
                  border: `2px solid ${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                }}
                className="conturi-container-left-row-core"
              >
                <div className="conturi-container-left-row-core-id">
                  {el.profile_picture ? <img src={el.profile_picture} /> : <AiOutlineUser />}
                </div>
                <div className="conturi-container-left-row-core-id">
                  <p>{el.user_name}</p>
                </div>
                <div className="conturi-container-left-row-core-id">
                  <div
                    style={{
                      backgroundColor: `${
                        index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"
                      }`,
                    }}
                    className="conturi-container-left-row-role"
                  >
                    {el.account_type}
                  </div>
                </div>
              </div>
              <img src="/media/icons/delete.png" onClick={() => handleDeleteClick(el.id)} />
            </div>
          );
        })}
      </div>
      <div className="conturi-container-right">
        <div className="conturi-container-right-row">
          <p>Adauga un cont nou:</p>
        </div>

        {/* Name */}
        <div className="conturi-container-right-row">
          <CssTextField
            label="Nume și prenume"
            sx={{ width: "100%" }}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              setNameValid(true);
            }}
          />
          {!nameValid && (
            <p style={{ color: "#D10000" }}> Te rog să introduci numele și prenumele</p>
          )}
        </div>

        {/* Email */}
        <div className="conturi-container-right-row">
          <CssTextField
            label="Username"
            sx={{
              width: "100%",
            }}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailValid(true);
            }}
          />
          {!emailValid && (
            <p style={{ color: "#D10000" }}>
              Te rog să introduci un nume de utilizator valid (fară spații)
            </p>
          )}
        </div>

        {/* Password */}
        <div className="conturi-container-right-row">
          <CssTextField
            label="Parolă"
            sx={{
              width: "100%",
            }}
            value={password}
            type="text"
            autoComplete="new-password"
            onChange={(event) => {
              setPassword(event.target.value);
              setPasswordValid(true);
            }}
          />
          {!passwordValid && (
            <p style={{ color: "#D10000" }}>Te rog să introduci o parolă (cel puțin 6 caractere)</p>
          )}
        </div>

        {/* Profile Picture URL */}
        <div className="conturi-container-right-row">
          <CssTextField
            label="Link poza profil"
            sx={{
              width: "100%",
            }}
            value={profilePicture}
            onChange={(event) => {
              setProfilePicture(event.target.value);
              setProfilePictureValid(true);
            }}
          />
          {!profilePictureValid && (
            <p style={{ color: "#D10000" }}>Te rog să introduci un link valid</p>
          )}
        </div>

        {/* Account Type */}
        <div className="conturi-container-right-row">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tip cont</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tip cont"
              value={accountType}
              onChange={(event) => setAccountType(event.target.value)}
            >
              <MenuItem value="Instructor">Instructor</MenuItem>
              <MenuItem value="Cursant">Cursant</MenuItem>
            </Select>
          </FormControl>
          {!accountTypeValid && (
            <p style={{ color: "#D10000" }}>Te rog sa selectezi tipul contului</p>
          )}
        </div>

        <div className="conturi-container-right-row">
          <Button
            style={{
              backgroundColor: "var(--main)",
              padding: "18px 36px",
              fontSize: "18px",
              marginRight: "20px",
            }}
            variant="contained"
            onClick={handleAddClick}
          >
            Adauga
          </Button>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>{modalMessage}</p>
            <span className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
