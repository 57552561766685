import React from "react";
import { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import Section9 from "../components/Landing/Section9";
import "./Galerie.css";
import LighBoxScroller from "../components/Atoms/LighBoxScroller";

export default function Galerie() {
  const [photos, setPhotos] = useState([]);
  const fetchEntities = async () => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getEntities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          setPhotos(data.entities[0].photos.links);
        }
      });
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <div className="landing-container">
      <TopBar />
      <div className="landing-container-core">
        <div className="gallery-container">
          <LighBoxScroller images={photos} landscape={true} />
        </div>
        <Section9 />
      </div>
    </div>
    // <>
    //     {
    //         photos.map((el, index) => {
    //             return (
    //                 <div className='parcauto-container-content-el' key={index}>
    //                     <img src={el} />
    //                 </div>
    //             )
    //         })
    //     }
    //     <p>Galerie</p>
    // </>
  );
}
