import React from "react";
import "./Section9.css";

export default function Section9() {
  const section1 = [
    ["Acasă", "/#hero"],
    ["Conectare", "/login"],
    ["Contact", "/contact"],
  ];
  const section2 = [
    ["Despre noi", "/#despre"],
    ["Galerie", "/galerie"],
    ["Prețuri", "/#preturi"],
  ];

  return (
    <div className="section9-container">
      <div className="section9-container-left">
        <img src="/media/logo.png" />
        <p>
          Suntem aici pentru a vă ghida în călătoria spre obținerea permisului de conducere. Cu
          instructori dedicați, programe flexibile și vehicule moderne, vă oferim o experiență de
          învățare sigură și eficientă.
        </p>
        <div className="section9-container-left-social">
          <a href="https:/https://www.autojuv.magnusbooking.ro/api.whatsapp.com/send/?phone=40736510500">
            <img src="/media/icons/social/wapp.png" />
          </a>
          {/* <a href="/">
                        <img src="/media/icons/social/ig.png"/>
                    </a> */}
          <a href="https://www.facebook.com/autojuv/">
            <img src="/media/icons/social/fb.png" />
          </a>
        </div>
      </div>
      <div className="section9-container-center">
        <div className="section9-container-center-section">
          <h3>Pagini</h3>
          {section1.map((el) => {
            return <a href={el[1]}>{el[0]}</a>;
          })}
        </div>
        <div className="section9-container-center-section">
          <h3>Secțiuni</h3>
          {section2.map((el) => {
            return <a href={el[1]}>{el[0]}</a>;
          })}
        </div>
      </div>
      <div className="section9-container-right">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.718883362115!2d27.577552676773646!3d47.163359918479784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb32aae1f5ff%3A0xdf1c9801c16bf908!2sScoala%20de%20Soferi%20Auto%20Juv!5e0!3m2!1sro!2sro!4v1701117457300!5m2!1sro!2sro"
          style={{
            border: "none",
            outline: "none",
          }}
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
