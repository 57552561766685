import React, { useRef, useState, useEffect } from "react";
import "../pages/Administrator/Chestionar.css";
import ReactToPrint from "react-to-print";

export default function ResultsPageChestionare({
  score,
  quizData,
  answers,
  handleBackButtonClick,
}) {
  const componentRef = useRef();

  return (
    <>
      <div className="rezultat-chestionar-container" ref={componentRef}>
        <div className="rezultat-chestionar-container-header">
          <div className="rezultat-chestionar-container-header-item">
            <button onClick={handleBackButtonClick}>Ieși din chestionar</button>
            <img src="/media/icons/exit.png" />
          </div>
          <div className="rezultat-chestionar-container-header-item">
            <ReactToPrint
              trigger={() => <button>Printează!</button>}
              content={() => componentRef.current}
            />
            <img src="/media/icons/printer.png" />
          </div>
        </div>
        {(score / quizData.questions.length) * 100 >= 91.67 ? (
          <p style={{ fontSize: "20px" }}>
            <span style={{ color: "green", fontSize: "40px", fontWeight: "bold" }}>Admis</span>
            <br></br>
            punctaj: {score}/{quizData.questions.length}
          </p>
        ) : (
          <p style={{ fontSize: "20px" }}>
            <span style={{ color: "red", fontSize: "40px", fontWeight: "bold" }}>Respins </span>
            <br></br>
            punctaj: {score}/{quizData.questions.length}
          </p>
        )}
        {answers.map((answer, index) => {
          return <RezultatChestionarItem answer={answer} index={index} key={index} />;
        })}
      </div>
    </>
  );
}

const RezultatChestionarItem = ({ answer, index }) => {
  console.log("A:", answer);
  console.log("B:", index);

  const indexAlphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

  const decidecCuloareRasp = (option, optionIndex) => {
    const isOptionSelected = answer.userAnswers.some(
      (userAnswer) => userAnswer.index === optionIndex
    );
    if (option.isCorrect) {
      if (isOptionSelected) return "green";
      return "lightgreen";
    } else {
      if (isOptionSelected) return "red";
      return "";
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(241, 241, 241)",
        marginBottom: "30px",
        marginTop: "30px",
        height: "auto",
        borderRadius: "10px",
        padding: "30px",
      }}
      className="chestionar-container"
    >
      <div className="rezultat-chestionar-container-header">
        <p
          style={{
            color: `${answer.isCorrect ? "green" : "red"}`,
            fontSize: "1.3rem",
          }}
        >
          Raspuns: <span>{answer.isCorrect ? "Corect" : "Greșit"}</span>
        </p>
      </div>
      <div className="chestionar-container-content">
        <div
          className="chestionar-container-content-top"
          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <p>
            Întrebarea {index + 1}: {answer.question.questionText}
          </p>
          {answer.question.questionImage && (
            <img src={answer.question.questionImage} alt="question" style={{ width: "30%" }} />
          )}
        </div>
        <div className="chestionar-container-content-values">
          <div
            className="chestionar-container-content-values-ques chestionar-container-content-values-ques-answers"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              flexDirection: `${
                answer.question.answerOptions[0].answerImage !== undefined ? "row" : "column"
              }`,
            }}
          >
            {answer.question.answerOptions.map((option, optionIndex) => {
              return (
                <p
                  key={optionIndex}
                  style={{
                    backgroundColor: `${decidecCuloareRasp(option, optionIndex)}`,
                    marginBottom: `${
                      optionIndex === answer.question.answerOptions.length - 1 ? "0px" : "30px"
                    }`,
                    width: `${option.answerImage !== undefined ? "50%" : "100%"}`,
                    minWidth: "300px",
                    maxWidth: `${option.answerImage !== undefined ? "30%" : "100%"}`,
                    margin: "10px",
                  }}
                >
                  <span>{indexAlphabet[optionIndex]}</span> {option.answerText}
                  <div className="chestionar-container-content-values-ques-image">
                    {option.answerImage && (
                      <img src={option.answerImage} alt="answer" style={{ marginLeft: "10px" }} />
                    )}
                  </div>
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className="page-break"></div>
    </div>
  );
};
