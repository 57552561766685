import React from 'react';
import './SectionTitle.css';

export default function SectionTitle({title, side})
{
    return(
        <>
        {
            side == true ?
            <div className='sectiontitle-container'>
            <p className='sectiontitle-container-title'>{title}</p>
            <p className='sectiontitle-container-line-top'></p>
            <p className='sectiontitle-container-line-bot'></p>
        </div>
        :
        <div className='sectiontitle-container-revert'>
        <p className='sectiontitle-container-title'>{title}</p>
        <p className='sectiontitle-container-line-top'></p>
        <p className='sectiontitle-container-line-bot'></p>
    </div>
        }
        </>

    )
}