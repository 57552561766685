import React from'react';
import './RezultatChestionar.css';

export default function RezultatChestionar()
{
    const intrebari = [
        {
            corect:true,
            raspunsBifat: `Circulați înainte sau la stânga`,
            raspunsCorect: `Circulați înainte sau la stânga`,
            question: `Ce obligație aveți dacă întâlniți într-o intersecție indicatorul „Înainte sau la stânga“ și semaforul se află pe culoarea verde?`,
            ans: [
                `Circulați înainte sau la stânga`,
                `Circulați numai înainte;`,
                `Circulați în orice direcție, deoarece semnalul verde vă permite acest lucru.`,
            ],
            image: `https://www.gandul.ro/wp-content/uploads/2021/02/imgd.jpg`
        },
        {
            corect: false,
            raspunsBifat:  `Circulați numai înainte;`,
            raspunsCorect: `Circulați în orice direcție, deoarece semnalul verde vă permite acest lucru.`,
            question: `Ce obligație aveți dacă întâlniți într-o intersecție indicatorul „Înainte sau la stânga“ și semaforul se află pe culoarea verde?`,
            ans: [
                `Circulați înainte sau la stânga`,
                `Circulați numai înainte;`,
                `Circulați în orice direcție, deoarece semnalul verde vă permite acest lucru.`,
            ],
        },
        {
            corect:true,
            raspunsBifat: `Circulați înainte sau la stânga`,
            raspunsCorect: `Circulați înainte sau la stânga`,
            question: `Ce obligație aveți dacă întâlniți într-o intersecție indicatorul „Înainte sau la stânga“ și semaforul se află pe culoarea verde?`,
            ans: [
                `Circulați înainte sau la stânga`,
                `Circulați numai înainte;`,
                `Circulați în orice direcție, deoarece semnalul verde vă permite acest lucru.`,
            ],
            image: `https://www.gandul.ro/wp-content/uploads/2021/02/imgd.jpg`
        }
    ]


    return(
        <div className='rezultat-chestionar-container'>
            {
                intrebari.map((el)=>{
                    return(
                       <RezultatChestionarItem  intrebareCore={el}/>
                    )
                })
            }
        </div>
    )
}

const RezultatChestionarItem = ({intrebareCore})=>{
    console.log("intrebareCore:",intrebareCore)
    
    const indexAlphabet = ['A','B','C']

    const decidecCuloareRasp = (raspunsCurent)=>{
        if(intrebareCore.corect === true)
        {
            if(raspunsCurent === intrebareCore.raspunsBifat) return 'green'
            return ''
        }
        else 
        {
            if(raspunsCurent == intrebareCore.raspunsBifat) return 'red'
            if(raspunsCurent == intrebareCore.raspunsCorect) return 'green'
            return ''
        }
    }
    return(
        <div
            style={{
                backgroundColor: 'rgb(241, 241, 241)',
                marginBottom: '30px',
                height: 'auto',
                borderRadius: '10px',
                padding: '30px'
            }} 
            className='chestionar-container'>
            <div className='rezultat-chestionar-container-header'>
                <p
                    style={{
                        color: `${intrebareCore.corect === true ? 'green':'red'}`,
                        fontSize: '1.3rem'
                    }}
                >Raspuns: <span>{intrebareCore.corect === true ? 'Corect':'False'}</span></p>
            </div>
            <div className='chestionar-container-content'>
                <div className='chestionar-container-content-top'>
                    <p>{intrebareCore["question"]}</p>
                </div>
                <div className='chestionar-container-content-values'>
                        <div
                            style={{
                                width: `${intrebareCore['image'] === undefined ? '100%':'60%'}`
                            }}
                            className='chestionar-container-content-values-ques'>
                            {
                                intrebareCore["ans"].map((ansEl, andIndex)=>{
                                    return(
                                        <p
                                            style={{
                                                backgroundColor: `${decidecCuloareRasp(ansEl)}`,
                                                marginBottom: `${andIndex === intrebareCore['ans'].length-1 ? '0px':'30px'}`
                                            }}
                                        ><span>{indexAlphabet[andIndex]}</span> {ansEl}</p>
                                    )
                                })
                            }
                        </div>
                        {
                            intrebareCore['image'] === undefined ? null : 
                            <div className='chestionar-container-content-values-ques-img'>
                                <img src={intrebareCore["image"]} alt="Imagine Intrebare"/>
                            </div>
                        }
                </div>
            </div>
        </div>
    )
}