import React from "react";
import "./Contact.css";
import TopBar from "../components/TopBar";

export default function Contact() {
  const items = [
    ["/media/contact/phone.png", "+(40) 736 510 500"],
    ["/media/contact/mail.png", "autojuviasi@gmail.com"],
    [
      "/media/contact/map.png",
      "Scoala de Soferi Auto Juv, Nr. 12 C, Strada Iordachi Lozonschi, Iași 700066",
    ],
  ];
  return (
    <div className="contact-container">
      <TopBar />
      <div className="contact-container-core">
        <div className="contact-container-core-content">
          <div className="contact-container-core-content-left">
            <div className="contact-container-core-content-left-header">
              <h3>Informații contact</h3>
              <p>Te-ai săturat să fii pieton ?</p>
            </div>
            <div className="contact-container-core-content-left-content">
              {items.map((el) => {
                return (
                  <div className="contact-container-core-content-left-content-row">
                    <img src={el[0]} />
                    <p>{el[1]}</p>
                  </div>
                );
              })}
            </div>

            <div className="section9-container-left-social">
              <a href="https:/https://www.autojuv.magnusbooking.ro/api.whatsapp.com/send/?phone=40736510500">
                <img src="/media/icons/social/wapp.png" />
              </a>
              {/* <a href="/">
                            <img src="/media/icons/social/ig.png"/>
                        </a> */}
              <a href="https://www.facebook.com/autojuv/">
                <img src="/media/icons/social/fb.png" />
              </a>
            </div>
          </div>
          <div className="contact-container-core-content-right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.718883362115!2d27.577552676773646!3d47.163359918479784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb32aae1f5ff%3A0xdf1c9801c16bf908!2sScoala%20de%20Soferi%20Auto%20Juv!5e0!3m2!1sro!2sro!4v1701117457300!5m2!1sro!2sro"
              style={{
                border: "none",
                outline: "none",
              }}
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
