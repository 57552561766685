import React from "react";
import "./DashTemplate.css";
import LeftBar, { items } from "./LeftBar";
import { useContext } from "react";
import { useSession } from "../common-backend/SessionContext";
import { useLocation } from "react-router-dom";
import { SessionContext, UserDataContext } from "../common-backend/SessionContext";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function DashTemplate({ menuIndex, CoreEl }) {
  const { session } = useSession();
  const userInfo = session.userInfo;
  const { account_type, user_name, profile_picture } = userInfo;
  const navigate = useNavigate();

  const handleLogout = () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.message ? navigate("/login") : console.log(data);
        Cookies.remove("session");
      });
  };

  const [burgerOpen, setBurgerOpen] = React.useState(false);
  const handleBurger = () => {
    setBurgerOpen(!burgerOpen);
  };

  // Left bar menu logic
  const location = useLocation();

  const items = {
    Admin: [
      ["Vezi instructori", "/vezi-instructori"],
      ["Serii", "/serii"],
      ["Intrebari", "/intrebari"],
      ["Cursanti", "/cursanti"],
      ["Parc Auto", "/parc-auto"],
      ["Conturi", "/conturi"],
      ["Echipa", "/echipa"],
      ["Rezultate", "/rezultate"],
    ],
    Instructor: [["Programează", "/programeaza"]],
    Cursant: [["Student. Info", "/info"]],
  };
  if (userInfo.isUserAllowedToViewQuestionnaires) {
    items["Cursant"].push(["Chestionar", "/chestionar"]);
  }

  const handleRedirect = (index) => {
    window.location.href = items[userInfo.account_type][index][1];
  };

  return (
    <div className="dashtitle-container">
      <LeftBar menuIndex={menuIndex} />
      <div className="dashtitle-container-core">
        <div className="dashtitle-container-core-header">
          <img
            src="/media/menu.png"
            className={`${
              burgerOpen
                ? "dashtitle-container-core-header-burger dashtitle-container-core-header-burger-active"
                : "dashtitle-container-core-header-burger"
            }`}
            onClick={handleBurger}
          />
          {/* <h2>{items[menuIndex][0]}</h2> */}
          <div className="dashtitle-container-core-header-acc">
            <button onClick={handleLogout}>Deconectare</button>
            <div className="dashtitle-container-core-header-acc-core">
              <p>{user_name}</p>
              <span>{account_type}</span>
            </div>
            <img src="/media/icons/userIcon.png" />
          </div>
        </div>
        <CoreEl />
      </div>

      <div className={`${burgerOpen ? "mobile-menu mobile-menu-active" : "mobile-menu"}`}>
        {items[userInfo.account_type].map((el, index) => {
          return (
            <React.Fragment key={index}>
              <div onClick={() => handleRedirect(index)} className="mobile-menu-item-row">
                <img src="/media/icons/menuUnSelect.png " />
                <p>{el[0]}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
