import React, { useState, useEffect } from "react";
import "./Section1.css";

export default function Section1() {
  let slideItems = [
    "/media/hero_slide_1.png",
    "/media/hero_slide_2.png",
    "/media/hero_slide_3.png",
  ];

  const [num, setNum] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNum((num) => (num + 1) % slideItems.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const [description, setDescription] = useState("");
  const fetchEntities = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getEntities", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("ERRR:", data);
        if (data.error) {
          console.log(data.error.message);
        } else {
          console.log(data.entities[0]);
          setDescription(data.entities[0].description);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <div className="section1-cont" id="hero">
      <div className="section1-banner">
        <p>{description}</p>
      </div>
      <div className="section1-cont-left">
        <img src={slideItems[num]} alt="car img" />
      </div>
      <div className="section1-cont-right">
        <img src="/media/logo.png" />
        <div className="section1-cont-right-core">
          <p>Te-ai săturat să fii pieton ?</p>
          <button
            onClick={() => {
              window.location.href = "/contact";
            }}
          >
            Fii șofer!
          </button>
        </div>
      </div>
    </div>
  );
}
