import React from "react";
import { useEffect } from "react";
import "./TopBar.css";
import { useState } from "react";

export default function TopBar() {
  // burger-bar.png
  const items = [
    {
      title: "Acasă",
      link: "/#hero",
    },
    {
      title: "Despre noi",
      link: "/#despre",
    },
    {
      title: "Galerie",
      link: "/galerie",
    },
    {
      title: "Prețuri",
      link: "/#preturi",
    },
    {
      title: "Informații",
      link: "/#info",
      deep: [
        ["Etapele școlarizării", "/#etape"],
        ["Întrebări frecvente", "/#intrebari"],
        ["Acte necesare", "/#acte-necesare"],
      ],
    },
    {
      title: "Contact",
      link: "/contact",
    },
    {
      title: "Blog",
      link: "/blog",
    },
  ];

  let itemsMobile = [
    ...items,
    {
      title: "Conectare",
      link: "/login",
    },
  ];

  const [activeBurger, setActiveBurger] = useState(false);
  const switchBurger = () => {
    setActiveBurger((prev) => {
      return !prev;
    });
  };
  const handleClose = () => {
    setActiveBurger(false);
  };

  useEffect(() => {
    console.log("activeBurger update:", activeBurger);
    if (activeBurger == true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [activeBurger]);

  return (
    <>
      <div className="top-bar-container">
        <div className="top-bar-container-left">
          <img
            src="/media/logo.png"
            onClick={() => {
              window.location.href = "/";
            }}
          />
        </div>
        <div className="top-bar-container-center">
          <div className="top-bar-container-center-items">
            <ul>
              {items.map((el, index) => {
                if (el.deep == undefined) {
                  return (
                    <li key={index}>
                      <a href={el.link}>{el.title}</a>
                    </li>
                  );
                } else {
                  return (
                    <li key={el.title}>
                      <a href={el.link}>{el.title}</a>
                      <ul className="dropdown">
                        <>
                          {el.deep.map((el_deep, deepIndex) => {
                            return (
                              <li key={deepIndex}>
                                <a href={el_deep[1]}>{el_deep[0]}</a>
                              </li>
                            );
                          })}
                        </>
                      </ul>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        <div className="top-bar-container-right">
          <div className="top-bar-container-right-phone">
            <img src="/media/icons/phone.png" alt="phone number" />
            <b>
              <p>+(40) 736 510 500</p>
            </b>
          </div>
          <div className="top-bar-container-right-login">
            <a href="/login">Conectare</a>
          </div>
        </div>
      </div>

      <div className="top-bar-container-mobile">
        <div className="top-bar-container-mobile-front">
          <div className="top-bar-container-mobile-front-logo">
            <img
              src="/media/logo.png"
              onClick={() => {
                window.location.href = "/";
              }}
            />
          </div>
          <div
            className={
              activeBurger === true
                ? "top-bar-container-mobile-front-burger-active"
                : "top-bar-container-mobile-front-burger"
            }
          >
            <img onClick={switchBurger} src="/media/burger-bar.png" />
          </div>
        </div>
        <div
          className={
            activeBurger
              ? "top-bar-container-mobile-back top-bar-container-mobile-back-active"
              : "top-bar-container-mobile-back"
          }
        >
          {itemsMobile.map((el) => {
            return (
              <>
                <a href={el.link} onClick={handleClose}>
                  {el.title}
                </a>
                {el.deep !== undefined ? (
                  <>
                    {el.deep.map((el_d) => {
                      return (
                        <a href={el_d[1]} onClick={handleClose}>
                          {el_d[0]}
                        </a>
                      );
                    })}
                  </>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
