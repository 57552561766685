import React from "react";
import { useEffect, useState } from "react";
import "./Section3.css";
import SectionTitle from "../Atoms/SectionTitle";
import LighBoxScroller from "../Atoms/LighBoxScroller";

export default function Section3() {
  const [photos, setPhotos] = useState([]);
  const fetchEntities = async () => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getEntities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          setPhotos(data.entities[0].photos_second.links);
        }
      });
  };

  useEffect(() => {
    fetchEntities();
  }, []);
  return (
    <div className="section3-container">
      <SectionTitle title="Cursanții noștrii" side={false} />
      <div className="section3-container-core">
        {/* <Gallery images={images} /> */}
        <LighBoxScroller images={photos.reverse()} landscape={false} />
      </div>
    </div>
  );
}
