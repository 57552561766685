import React from "react";
import "./Section2.css";
import SectionTitle from "../Atoms/SectionTitle";
import Testimonials from "./Testimonials";

export default function Section2() {
  return (
    <div className="section2-cont" id="despre">
      <div className="section2-cont-left">
        <SectionTitle title="Despre noi" side={true} />
        <div className="section2-cont-left-content">
          <p>
            Suntem aici pentru a vă ghida în călătoria spre obținerea permisului de conducere. Cu
            instructori dedicați, programe flexibile și vehicule moderne, vă oferim o experiență de
            învățare sigură și eficientă.
          </p>
        </div>
        <Testimonials />
      </div>
      <div className="section2-cont-right">
        <img className="section2-cont-right-first" src="/media/section2_3_new.png" />
        <img className="section2-cont-right-second" src="/media/section2_1.png" />
      </div>
    </div>
  );
}
