import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSession } from "../common-backend/SessionContext";

function ProtectedRoute({ children, roles }) {
  const location = useLocation();
  const { session, setSession } = useSession();
  const [loading, setLoading] = useState(true); // Add loading state
  const [permission, setPermission] = useState(false); // Add loading state

  // console.log(session);

  const checkSession = async () => {
    // console.log('Checking session');
    try {
      const response = await fetch("https://www.autojuv.magnusbooking.ro/api/check-session", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.validation && roles.includes(session.userInfo.account_type)) {
        if (
          session.userInfo.account_type === "Cursant" &&
          session.userInfo.isUserAllowedToViewQuestionnaires &&
          location.pathname === "/chestionar"
        ) {
          setPermission(true);
          setLoading(false);
          return;
        } else {
          if (
            session.userInfo.account_type === "Cursant" &&
            !session.userInfo.isUserAllowedToViewQuestionnaires &&
            location.pathname === "/chestionar"
          ) {
            console.log("Cursantul nu are permisiunea de a vizualiza chestionarele");
            setPermission(false);
            setLoading(false);
            return;
          }
        }
        setPermission(true);
        setLoading(false);
      } else {
        setPermission(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setPermission(false);
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  if (loading) {
    // If loading is true, return null or a loading indicator
    return null;
  }

  return permission ? children : <Navigate to="/login" replace state={{ from: location }} />;
}

export default ProtectedRoute;
