import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useEffect, useState } from "react";
import "./Informatii.css";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSession } from "../../common-backend/SessionContext";

export default function Informatii() {
  const [value, setValue] = React.useState(dayjs());
  const [group, setGroup] = useState("");
  const { session } = useSession();

  useEffect(() => {
    fetchAppointments(modifyDate(value));
  }, [value]);

  const [adminMessage, setAdminMessage] = useState("");

  const [listaAppointments, setListaAppointments] = useState([]);
  const [docs, setDocs] = useState([]);

  const fetchAppointments = async (modifiedDate) => {
    fetch(
      `https://www.autojuv.magnusbooking.ro/api/getAppointments?userId=${
        session.session.user.id
      }&date=${modifiedDate}&docs=${true}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          setListaAppointments(data.appointments);
          setAdminMessage(data.docs[0].admin_message);
          setDocs(data.docs);
          setGroup(data.docs[0].group);
        }
      });
  };

  const modifyDate = (date) => {
    const isoString = date.toISOString();
    const dateObject = new Date(isoString);

    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  return (
    <div className="info-container">
      <div className="info-container-welcome">
        <b>
          <p>Bine ai revenit, {session.userInfo.user_name} !</p>
        </b>
        <p style={{ marginTop: "10px" }}>
          <b>Grupa ta este:</b> <span>{group}</span>
        </p>
      </div>
      <div className="info-container-docs-header">
        <p>Valabilitate documete:</p>
      </div>
      <div className="info-container-docs">
        {docs[0]?.files?.map((doc, index) => (
          <div className="info-container-docs-core" key={index}>
            <p>{doc.title}</p>
            <span>
              <b>{doc.date || ""}</b>
            </span>
          </div>
        ))}
        <div className="info-container-docs-core" key={"Rest"}>
          <p>Rest plata</p>
          <span>
            <b>{docs[0]?.payment ?? ""}</b>
          </span>
        </div>
      </div>
      <div className="info-container-docs-header">
        <p>Mesaj de la administrator:</p>
      </div>
      <div className="info-container-docs-header-admin-msj">,,{adminMessage}''</div>
      <div className="info-container-docs-header-date">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Calendar"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              fetchAppointments(modifyDate(newValue));
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="info-container-docs-items">
        {listaAppointments.map((el, index) => {
          console.log("el:", el);
          return (
            <div
              style={{
                backgroundColor: `${
                  index % 2 == 0 ? "rgb(41, 204, 57, 0.2)" : "rgb(51, 191, 255, 0.2)"
                }`,
                border: `2px solid ${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                display: "flex",
              }}
              className="vezi-profesori-left-right-bot-row"
              key={index}
            >
              <div className="vezi-profesori-left-right-bot-row-cell">
                <p>
                  {(el.appointment_type || "").split("\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
              <div className="vezi-profesori-left-right-bot-row-cell">
                <p
                  style={{
                    backgroundColor: `${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                  }}
                  className="vezi-profesori-left-right-bot-row-cell-hour"
                >
                  {el.time}
                </p>
              </div>
              <div className="vezi-profesori-left-right-bot-row-cell">
                <p>{"Instructor: " + el.instructor}</p>
              </div>
              <div className="vezi-profesori-left-right-bot-row-cell">
                {el.instructor_picture ? <img src={el.instructor_picture} /> : <AiOutlineUser />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
