import React, { useState, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import "./VeziProfesori.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSession } from "../../common-backend/SessionContext";

import { CssTextField } from "../Login";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  border: "none",
  outline: "none",
  borderRadius: "10px",
};

export default function VeziProfesori() {
  const { session } = useSession();
  const [users, setUsers] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [listaAppointments, setListaAppointments] = useState([]);
  const [value, setValue] = React.useState(dayjs());

  // Modal logic
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [errorPostingData, setErrorPostingData] = React.useState("");

  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);

  const modifyDate = (date) => {
    const isoString = date.toISOString();
    const dateObject = new Date(isoString);

    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const removeAppointments = async (appointmentData) => {
    //cursantID, appointment_type, , [oraStart, oraStop], cursant, instructorName, instructorPicture, Date
    const cursantInfo = students.find((user) => user.name === appointmentData.student);
    appointmentData.cursantID = cursantInfo.id;
    appointmentData.date = modifyDate(value);
    appointmentData.instructor = appointmentData.instructor;
    appointmentData.instructor_picture = appointmentData.instructor_picture;
    console.log(appointmentData);
    fetch(`https://www.autojuv.magnusbooking.ro/api/removeAppointments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify(appointmentData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setErrorPostingData(data.error);
          handleErrorOpen();
        } else {
          setErrorPostingData(data.message || "Programarea a fost stearsa cu succes!");
          fetchAppointments(appointmentData.date, selectedUser);
          handleErrorOpen();
        }
      });
  };

  const fetchUsers = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getUsers?profesor=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Eroare: " + data.error.message);
        } else {
          const users = data.users.map((user) => ({
            name: user.user_name,
            user_name: user.email.split("@")[0],
            profile_picture: user.profile_picture, // Assuming the user's ID is stored in the 'id' property
          }));
          setUsers(users);
          if (users.length > 0) {
            setSelectedUser(users[0].user_name); // Set the first user as the selected user
          }
        }
      });
  };

  const fetchAppointments = async (modifiedDate, selectedUserName) => {
    fetch(
      `https://www.autojuv.magnusbooking.ro/api/getAppointments?date=${modifiedDate}&instructor=${selectedUserName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        }
        setListaAppointments(data.appointments);
      });
  };

  const fetchStudents = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getUsers?student=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          const users = data.users.map((user) => ({
            name: user.user_name,
            user_name: user.email.split("@")[0],
            id: user.id, // Assuming the user's ID is stored in the 'id' property
          }));
          setStudents(users);
        }
      });
  };

  useEffect(() => {
    if (selectedUser) {
      fetchAppointments(modifyDate(value), selectedUser);
    }
  }, [value, selectedUser]);
  useEffect(() => {
    fetchUsers();
    fetchStudents();
  }, []);

  const [selected, setSelected] = useState(0);

  const translateDate = (date) => {
    const daysOfWeek = ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"];
    const months = [
      "Ianuarie",
      "Februarie",
      "Martie",
      "Aprilie",
      "Mai",
      "Iunie",
      "Iulie",
      "August",
      "Septembrie",
      "Octombrie",
      "Noiembrie",
      "Decembrie",
    ];

    const day = daysOfWeek[date.day()];
    const month = months[date.month()];

    return `${day} ${date.date()} ${month}`;
  };

  return (
    <div className="vezi-profesori">
      <div className="vezi-profesori-left">
        <div className="vezi-profesori-left-items">
          {users.map((el, index) => {
            return (
              <ItemProfesor
                key={index}
                image={el.profile_picture}
                name={el.name}
                selected={selected == index}
                handleClickSelect={() => {
                  setSelected(index);
                  setSelectedUser(el.name);
                  fetchAppointments(modifyDate(value), el.name);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="vezi-profesori-left-right">
        <div className="vezi-profesori-left-right-top">
          <h3>Selecteaza o zi:</h3>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Calendar"
              value={value}
              //onChange={(newValue) => setValue(newValue)}
              onChange={(newValue) => {
                setValue(newValue);
                fetchAppointments(modifyDate(newValue), selectedUser);
              }}
            />
          </LocalizationProvider>

          <Modal
            open={errorOpen}
            onClose={handleErrorClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ModalMesaj handleClose={handleErrorClose} errorPostingData={errorPostingData} />
            </Box>
          </Modal>
          <h4>{translateDate(value)}</h4>
        </div>
        <div className="vezi-profesori-left-right-bot">
          {listaAppointments.map((el, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: `${
                    index % 2 == 0 ? "rgb(41, 204, 57, 0.2)" : "rgb(51, 191, 255, 0.2)"
                  }`,
                  border: `2px solid ${index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"}`,
                }}
                className="vezi-profesori-left-right-bot-row"
              >
                <div className="vezi-profesori-left-right-bot-row-top">
                  <div className="vezi-profesori-left-right-bot-row-cell">
                    <p
                      style={{
                        backgroundColor: `${
                          index % 2 == 0 ? "rgb(41, 204, 57)" : "rgb(51, 191, 255)"
                        }`,
                      }}
                      className="vezi-profesori-left-right-bot-row-cell-hour"
                    >
                      {el.time}
                    </p>
                  </div>
                  <div className="vezi-profesori-left-right-bot-row-cell">
                    <p>
                      {(el.appointment_type || "").split("\n").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>

                  <div className="vezi-profesori-left-right-bot-row-cell">
                    <p>Elev: {el.student || ""}</p>
                  </div>

                  <div className="vezi-profesori-left-right-bot-row-cell">
                    {el.instructor_picture ? (
                      <img src={el.instructor_picture} />
                    ) : (
                      <AiOutlineUser />
                    )}
                  </div>

                  <div className="vezi-profesori-left-right-bot-row-cell">
                    <img src="/media/icons/delete.png" onClick={() => removeAppointments(el)} />
                  </div>
                </div>
                <div className="vezi-profesori-left-right-bot-row-bot">
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                    }}
                    className="vezi-profesori-left-right-bot-row-cell"
                  >
                    {el.instructor_changes &&
                      Array.isArray(el.instructor_changes.fullHistory) &&
                      el.instructor_changes.fullHistory.map((change, index) => (
                        <p key={index}>
                          Mesaj modificat la data de {change.dateAndTime} înainte de modificări:{" "}
                          {change.old} după modificări: {change.new}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const ItemProfesor = ({ image, name, selected, handleClickSelect }) => {
  return (
    <div
      onClick={handleClickSelect}
      style={{
        backgroundColor: `${selected == true ? "rgba(237, 27, 38, 0.25)" : "white"}`,
      }}
      className="vezi-profesori-left-items-item"
    >
      {image ? <img src={image} /> : <AiOutlineUser />}
      <p>{name}</p>
    </div>
  );
};

const ModalMesaj = ({ handleClose, errorPostingData }) => {
  return (
    <div className="vezi-profesori-left-modal">
      <h3
        style={{
          fontSize: "18px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {errorPostingData}
      </h3>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
          }}
          variant="contained"
          onClick={handleClose}
        >
          Închide
        </Button>
      </div>
    </div>
  );
};
