import React from "react";
import "./Grupe.css";
import { MdDeleteForever } from "react-icons/md";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CssTextField } from "../Login";
import { useSession } from "../../common-backend/SessionContext";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  border: "none",
  outline: "none",
  borderRadius: "10px",
};

export default function Intrebari() {
  const { session } = useSession();
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState([]);
  const [errorPostingData, setErrorPostingData] = React.useState("");

  // Modal logic
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Message modal logic
  const [messageOpen, setMessageOpen] = React.useState(false);
  const handleMessageOpen = () => setMessageOpen(true);
  const handleMessageClose = () => setMessageOpen(false);

  const addQandA = async (groupData) => {
    fetch("https://www.autojuv.magnusbooking.ro/api/addQandA", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify(groupData), // Include the appointment data in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          //console.log('Error fetching users: ' + data.error.message);
          setErrorPostingData(data.error.message);
          handleMessageOpen();
          fetchGroups();
        } else {
          console.log(data);
          setErrorPostingData(data.message);
          handleMessageOpen();
          fetchGroups();
        }
      });
  };

  const removeQandA = async (groupData) => {
    fetch("https://www.autojuv.magnusbooking.ro/api/removeQandA", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify(groupData), // Include the appointment data in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          //console.log('Error fetching users: ' + data.error.message);
          setErrorPostingData(data.error.message);
          handleMessageOpen();
          fetchGroups();
        } else {
          console.log(data);
          setErrorPostingData(data.message);
          handleMessageOpen();
          fetchGroups();
        }
      });
  };

  const fetchGroups = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getQandA", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          console.log(data);
          setGroups(data.qanda);
        }
      });
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div className="grupe-container">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalIntrebari handleClose={handleClose} addQandA={addQandA} />
        </Box>
      </Modal>
      <Modal
        open={messageOpen}
        onClose={handleMessageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalMessage handleMessageClose={handleMessageClose} message={errorPostingData} />
        </Box>
      </Modal>

      <div className="grupe-container-header">
        <img src="/media/icons/button.png" onClick={handleOpen} />
        <p>Adauga întrebare</p>
      </div>
      <div className="grupe-container-content">
        {groups.map((el, index) => {
          return (
            <ElemSerie
              question={el.question}
              answer={el.answer}
              key={index}
              removeQandA={removeQandA}
            />
          );
        })}
      </div>
    </div>
  );
}

const ModalIntrebari = ({ handleClose, addQandA }) => {
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");

  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };
  const handleAnswer = (event) => {
    setAnswer(event.target.value);
  };
  return (
    <div className="grupe-container-modal">
      <h3>Adaugă întrebare</h3>
      <div className="vezi-profesori-left-modal-row">
        <CssTextField
          label="Întrebare"
          sx={{
            width: "100%",
          }}
          onChange={(event) => {
            handleQuestion(event);
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row">
        <CssTextField
          label="Răspuns"
          sx={{
            width: "100%",
          }}
          onChange={(event) => {
            handleAnswer(event);
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
            marginRight: "20px",
          }}
          variant="contained"
          onClick={() => {
            addQandA({ question: question, answer: answer });
            handleClose();
          }}
          disabled={!question || !answer}
        >
          Adauga
        </Button>
      </div>
    </div>
  );
};

const ModalMessage = ({ handleMessageClose, message }) => {
  return (
    <div className="grupe-container-modal">
      <h3
        style={{
          fontSize: "18px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {message}
      </h3>

      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
          }}
          variant="contained"
          onClick={handleMessageClose}
        >
          Închide
        </Button>
      </div>
    </div>
  );
};

const ElemSerie = ({ question, answer, removeQandA }) => {
  const handleDeleteClick = () => {
    removeQandA({ question: question, answer: answer });
  };
  return (
    <div className="vezi-profesori-left-item">
      <div className="vezi-profesori-left-item-row">
        <p>Întrebare</p>
        <span>{question}</span>
      </div>
      <div className="vezi-profesori-left-item-row">
        <p>Răspuns</p>
        <span>{answer}</span>
      </div>
      <div className="vezi-profesori-left-item-btn" onClick={handleDeleteClick}>
        <p>
          <MdDeleteForever style={{ color: "white" }} />
          <span>Șterge</span>
        </p>
      </div>
    </div>
  );
};
