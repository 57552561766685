import React from "react";
import "./LeftBar.css";
import { UserDataContext } from "../common-backend/SessionContext";
import { useContext } from "react";
import { useSession } from "../common-backend/SessionContext";
import { useLocation } from "react-router-dom";

export const items = [
  ["Vezi instructori", "/vezi-instructori"],
  ["Serii", "/serii"],
  ["Intrebari", "/intrebari"],
  ["Cursanti", "/cursanti"],
  ["Parc Auto", "/parc-auto"],
  ["Conturi", "/conturi"],
  ["Echipa", "/echipa"],
  ["Programează", "/programeaza"],
  ["Student. Info", "/info"],
  ["Chestionar", "/chestionar"],
  ["Rezultate", "/rezultate"],
  ["Rezolvare Chestionar", "/rezultat-chestionar"],
];
export default function LeftBar({ menuIndex }) {
  const location = useLocation();
  const { session } = useSession();
  const userInfo = session.userInfo;

  const items = {
    Admin: [
      ["Vezi instructori", "/vezi-instructori"],
      ["Serii", "/serii"],
      ["Intrebari", "/intrebari"],
      ["Cursanti", "/cursanti"],
      ["Parc Auto", "/parc-auto"],
      ["Conturi", "/conturi"],
      ["Echipa", "/echipa"],
      ["Rezultate", "/rezultate"],
    ],
    Instructor: [["Programează", "/programeaza"]],
    Cursant: [["Student. Info", "/info"]],
  };
  if (userInfo.isUserAllowedToViewQuestionnaires) {
    items["Cursant"].push(["Chestionar", "/chestionar"]);
  }

  const handleRedirect = (index) => {
    window.location.href = items[userInfo.account_type][index][1];
  };

  menuIndex = items[userInfo.account_type].findIndex((item) => item[1] === location.pathname);

  return (
    <div className="leftbar-container">
      <div className="leftbar-container-logo">
        <img
          src="/media/logo.png"
          onClick={() => {
            window.location.href = "/";
          }}
        />
      </div>
      <div className="leftbar-container-items">
        {items[userInfo.account_type].map((el, index) => {
          return (
            <React.Fragment key={index}>
              {index == menuIndex ? (
                <div
                  onClick={() => handleRedirect(index)}
                  className="leftbar-container-items-row-selected"
                >
                  <img src="/media/icons/menuSelect.png" />
                  <p>{el[0]}</p>
                </div>
              ) : (
                <div onClick={() => handleRedirect(index)} className="leftbar-container-items-row">
                  <img src="/media/icons/menuUnSelect.png " />
                  <p>{el[0]}</p>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
